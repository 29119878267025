<template>
<section>
    <div class="w-full flex my-2">
      <div class="w-1/2 flex">
        <label for="buscar" class="my-auto">Buscar artículo</label>
        <input
        type="text"
        name="buscar"
        class="form-control ml-2"
        v-model="filter.query"
        placeholder="Buscar por artículo">
      </div>
    </div>
    <!-- botones -->
    <div class="w-full flex items-center my-2">
      <button
      type="button"
      class="btn btn-primary"
      @click="onFilter"
      >
        Filtrar
      </button>

      <button
      type="button"
      class="btn btn-warning ml-2"
      @click="onFilterClear"
      >
        Limpiar
      </button>
    </div>
    <div class="w-ful">
      <DataTable @sort="orderFiled" :value="getInventary">
        <Column field="brand" header="Imagen" headerStyle="width:90px; ">
          <template #body="{data}">
            <img :src="getImage(data.articlePresentation.article)" style="width:50px;" class="rounded-md" />
          </template>
        </Column>
        <Column field="code" header="Cod. Producto" sortable headerStyle="width:100px;" />
        <Column field="name" header="Artículo" sortable headerStyle="width: 240px;" />
        <Column header="Categoria">
          <template #body="{data}">
            {{data.articlePresentation.article.category.name}}
            {{data.articlePresentation.article.sub_category ? ' > ' + data.articlePresentation.article.sub_category.name : ''}}
          </template>
        </Column>
        <Column field="stock" header="Cantidad" sortable />
        <Column field="unit" header="Und Medida" sortable />
        <Column field="minimum_stock" header="Min stock" sortable >
          <template #body="{data}">
            <span v-if="data.stock > data.minimum_stock" class="bg-green-700 px-3 py-1 rounded-md text-white">{{data.minimum_stock}}</span>
            <span v-else class="bg-red-500 px-3 py-1 rounded-md text-white">{{data.minimum_stock}}</span>
          </template>
        </Column>

      </DataTable>
    </div>
    <div class="w-full">
      <Paginator
      :rows="getPaginate.perPage"
      :totalRecords="getPaginate.total"
      :rowsPerPageOptions="[10,20,30]"
      :first="getPaginate.first"
      @page="onPage($event)"
      />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive, watch, ref } from 'vue'
import apiListInventary from '@/apps/pharmasan/comprasAdministrativas/services/listArticleInventary'
import DataTable from 'primevue/datatable'
import Paginator from 'primevue/paginator'
import Column from 'primevue/column'
import { useRoute, useRouter } from 'vue-router'
import configShops from '../../../../../../../config/shops'

export default {
  name: 'listadoArticulos',
  components: {
    DataTable,
    Column,
    Paginator
  },
  setup () {
    /*
    * Instances
    */
    const route = useRoute()
    const router = useRouter()
    const filter = ref({
      query: ''
    })
    /*
    * Refs and reactives
    */
    const inventary = reactive({
      data: []
    })

    const getPaginate = computed(() => {
      return {
        perPage: inventary.data.per_page,
        total: inventary.data.total,
        first: +route.query.first || 0
      }
    })

    /*
    * Computeds
    */
   const getInventary = computed(() => {
     return inventary.data.data
   })

    /*
    * hooks
    */
   const onFilter = () => {
      const queryParams = route.query
      router.push({ query: { ...queryParams, ...filter.value } })
    }
    const filterFill = () => {
      const { query } = route.query
      filter.value.query = query || ''
    }
    onMounted(() => {
      filterFill()
      fetchInventary()
    })
    watch(() => route.query, (value) => {
      fetchInventary()
    })

    /*
    * Methods
    */

  const fetchInventary = () => {
    const queryParams = { ...route.query }
    queryParams.page = queryParams.page ? queryParams.page : 1
    queryParams.paginate = queryParams.paginate ? queryParams.paginate : 10
    return apiListInventary(queryParams).then(({ status, data }) => {
      if (status !== 200) return false
      for (const [key, value] of Object.entries(data)) {
        inventary.data[key] = value
      }
    }).catch((err) => {
      console.error(err)
    })
  }
  const onPage = (row) => {
    const page = row.page + 1
    const paginate = row.rows
    const queryParams = { ...route.query }
    const first = row.first
    router.push({ query: { ...queryParams, page, first, paginate, ...filter.value } })
  }

  const onFilterClear = () => {
    filter.value.query = ''
  }

  const getImage = ({ picture, name }) => {
    if (picture) return `${configShops.api}/files/${picture}`
    return `https://ui-avatars.com/api/?background=125386&color=fff&name=${name}`
  }

  const orderFiled = ({ sortField, sortOrder }) => {
    const queryParams = route.query
    router.push({ query: { ...queryParams, order: `${sortField},${sortOrder}` } })
  }

  return {
      filter,
      getInventary,
      getPaginate,
      onPage,
      getImage,
      onFilterClear,
      onFilter,
      orderFiled

    }
  }
}
</script>
